<template>
  <div class="home">
    <img class="img-bg" src="~@/assets/bg.png" />

    <div class="div-info">
      <p class="item-title">三生有幸遇见你，我在PC版等着你</p>
      <p class="item-desc">
        墨记网页版已经下线，请尽快升级为PC版，享受更便捷流畅的记录体验～
      </p>

      <div class="div-download">
        <img class="img-logo" src="~@/assets/moji_avatar.png" />
        <a class="btn-download" href="http://moji01.com/download" target="view_window"
          >下载墨记PC版</a
        >
      </div>
      <!-- <a class="a-link-web" href="http://mojime.cn" target="view_window"
        >继续访问网页版</a
      > -->
    </div>

    <div class="div-record">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        class="moji-record"
        target="view_window"
        >粤ICP备19024063号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePC",
  components: {},
  methods: {
    goProduct() {
      let div = document.getElementsByClassName("div-product")[0];
      div.scrollIntoView();
    },

    goContact() {
      let div = document.getElementsByClassName("div-about")[0];
      div.scrollIntoView();
    },
  },
};
</script>

<style scoped>
.home {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  background: #fafafa;
}

.img-bg {
  position: absolute;
  left: 50%;
  top: 10%;
  width: 100%;
  transform: translate(-50%, -10%);
}

.div-info {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-title {
  font-size: 32px;
  font-weight: 400;
  color: #333333;
}

.item-desc {
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  margin-top: 0px;
}

.div-download {
  margin-top: 32px;
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img-logo {
  height: 45px;
  width: 67px;
}

.btn-download {
  cursor: pointer;
  text-decoration: none;
  margin-top: -5px;
  background: #333333;
  font-size: 26px;
  font-weight: 400;
  color: #ffffff;
  border-radius: 6px;
  height: 74px;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-download:hover {
  background: #444444;
}

.a-link-web {
  cursor: pointer;
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  margin-top: 32px;
}

.div-record {
  position: absolute;
  bottom: 0;
  padding-bottom: 22px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.moji-record {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  text-align: left;
}
</style>
